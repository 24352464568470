
@font-face {
    font-family: "e";   /*Can be any text*/
    src: local("e"),
      url("/public/fonts/e.ttf") format("truetype");
}


@font-face {
    font-family: "a";   /*Can be any text*/
    src: local("a"),
      url("/public/fonts/a.ttf") format("truetype");
}


:root{
    font-size: 22px;
}

html, body{
    padding: 0;
    margin: 0 auto;
    max-width: 1920px;
    overflow-x: hidden;
}
body{
    background-color: #F8F8F8;
}
main{
    max-width: 80%;
    margin: 0 auto;
    padding-top: 3rem;
}

/* HEADER */

nav{
    position: fixed;
    width: 100%;
    background-color: rgba(248, 248, 248, 1);
    margin: 0 auto;
    z-index: 9999999999999;
    top: 0; left: 0;
    display: flex;
    padding-left: 10%;
}

h1{
    opacity: 0;
}

header, header a, header a:visited{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 0.909rem;
    font-weight: 400;

    text-align: center;
    color: #7E7E7E;
    text-decoration: none;
}

header a:hover, header a:active{
    font-weight: 700;
    transition: .3s ease-in-out;
    text-decoration: underline;
}

header ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
}

header ul li{
    padding: 0 10px;
    line-height: 70px;
}

.logo{
    font-family: e;
    color: #495226;
    font-size: 1.2rem;
    line-height: 1;
    background-image: url('/public/img/logo.png');
    background-size: cover;
    height: 80%;
}

.ulmenu{
    margin-left: 20%;
}

.menu{
    display: none;
    height: 14px;
    width: 40px;
    position: fixed;
    right: 10%;
}

/* Intro */ 

.intro{
    font-size: 4.364rem;
    text-align: center;
    font-family: a;
    color: #495226;
    line-height: 1;
    width: 80%;
    margin: 5.227rem auto;
    font-weight: 400;
}


.i1{
    content: "";
    position: relative;
    width: 147px;
    height: 61px;
    background: #76B6C4;
    border-radius: 50px;
    background-image: url('/public/img/bandol2.jpg');
    background-position: center;
    display: inline-block;
}

.i2{
    content: "";
    position: relative;
    width: 61px;
    height: 61px;
    background: #76B6C4;
    border-radius: 50px;
    display: inline-block;
    margin: auto 1rem;
}

.i3{
    content: "";
    position: relative;
    width: 147px;
    height: 61px;
    background: linear-gradient(180deg, rgba(118,182,196,0) 32%, rgba(118,182,196,1) 100%);
    border-radius: 50px;
    display: inline-block;
    margin: auto 1rem;
}

.i3::after{
    content: "";
    z-index: 1;
    position: absolute;
    width: 147px;
    height: 61px;
    left: 0;
    bottom: 0;
    border-radius: 50px;
    background-image: url('/public/img/bandol.png');
    display: inline-block;
}

.cards {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0;
    height: 524px;
}

.cards div{
    border-radius: 15px;
    background-size: cover;
}

.cards__el1 { 
    grid-area: 1 / 1 / 5 / 3; 
    background-image: url('/public/img/IMG_7841-min.JPG');
}
.cards__el2 { 
    grid-area: 1 / 3 / 5 / 6; 
    background-image: url('/public/img/IMG_7109-min.jpg');
    width: 80%;
    margin: 0 auto;

}
.cards__el3 { 
    grid-area: 1 / 6 / 3 / 10;; 
    background-image: url('/public/img/IMG_7106-min.jpg');
    background-position: center;
    height: 80%;
}
.cards__el4 { 
    grid-area: 3 / 6 / 5 / 10; 
    font-family: Poppins;
    color: #7E7E7E;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
}

.cards__el4 p{
    margin: 0;
}

.ensavoir, .ensavoir:visited{
    text-decoration: none;
    color: #7E7E7E;
    padding: 10px 30px;
    border: 1px solid #7E7E7E;
}

.button{
    margin-top: 20px;
}

/* Slick */ 

.container {
    padding: 1.818rem;
    text-align: center;
    width: 60%;
    margin: 2.727rem auto;
}

.container h3{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;    
    max-width: 80%;
    margin: 30px auto;
}
  
.slick-prev:before, .slick-next:before {
    color: #D9D9D9 !important;
    font-size: 36px !important;
}

/** pano **/ 

.pano .pnlm-container{
    border-radius: 15px !important;
}

.pnlm-controls-container{
    top: 20px !important;
    left: 20px !important;
}

/* Services */ 

.services{
    font-size: 4.364rem;
    font-family: a;
    font-weight: 400;
    color: #495226;
    line-height: 100px;
}

.services--list{
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.services--list::after{
    content: "";
    position: absolute;
    top: -30px; left: -20%;
    background: #D9D9D9;
    height: 2px; 
    width: 140%;
}

.services--list::before{
    content: "";
    position: absolute;
    bottom: -20px; left: -20%;
    background: #D9D9D9;
    height: 2px; 
    width: 140%;
}

.services--list li{
    display: flex;
}

.icone i, .icone img{
    max-width: 65px;
    max-height: 65px;
}

.icone h3{
    font-family: Poppins;
    font-weight: 400;
    padding: 0 1rem;
}


.photos img{
    height: 600px;
    width: 450px;
    border-radius: 15px;
    object-fit: cover;
}

.photos{
    margin: 6.818rem auto;
}

.photos .slick-next:before {
    color: #D9D9D9 !important;
    font-size: 64px !important;
}

.photos .slick-prev:before{
    display: none;
}

footer{
    background-color: #646B4B;
    color: white;
    font-family: a;
    font-size: 2rem;
    padding-top: 10px;
    padding-bottom: 50px;
}

footer h2{
    font-size: 4.364rem;
    font-family: a;
    font-weight: 400;
    color: white;
    line-height: 0;
}

footer ul{
    list-style-type: none;
    padding: 0;
    line-height: 2;
}

.footer{
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
    border-bottom: 2px solid #F8F8F8;
    display: flex;
    justify-content: space-between;
}

.footer_bottom{
    max-width: 80%;
    margin: 0 auto;
    color: black;
    font-family: Poppins;
    font-size: 1rem;
}

.adress{
    margin-top: 30px;
    line-height: 1.2;
}

/** CUSTOM PANO **/ 

.parent{
    width: 100%;
    overflow-x: hidden; 
    overflow-y: hidden;
    float:left;
    height: 800px;
    border-radius: 15px;
    margin-bottom: 5.227rem;
  }

  .container-parent{
    position: relative;
  }
  .container-parent::after{
    content: "";
    position: absolute;
    top: 1rem; left: 1rem;
    height: 40px; width: 40px;
    z-index: 1;
    background-image: url('/public/icons/swipe.png');
    background-size: cover;
    opacity: .8;
  }

  .child{
    float:left;
    font-size:15px;
    font-family:arial;
    cursor: pointer;
    height: 100%;
  }

  #services{
    position: absolute;
    top: -9rem;
  }

  /** GOOGLE MAPS **/ 

  .map{
    width: 800px;
    height: 500px;
    overflow: hidden;
    border-radius: 15px;
    margin: 1.8rem auto;
  }

  .red{
    width: 15px; height: 15px;
    background: red;
    border: 4px solid black;
    border-radius: 50%;
  }

  /** CALENDAR **/ 

  .react-calendar{
    width: 800px;
    height: 500px;
    border-radius: 15px;
  }

  
  .react-calendar__tile, .react-calendar__tile:disabled{
    background-color: green ;
    }
    .react-calendar__month-view__days__day--weekend{
        color: black !important;
    }
  .booked {
    color: white !important;
    background: red !important;
 }


@media (max-width: 900px) {
    :root{
        font-size: 12px;
    }

    body{
        font-size: 1.2rem;
    }
    .ulmenu{
        display: none;
    }

    .intro{
        width: 100%;
    }
    
    .i1{
        content: "";
        position: relative;
        width: 78px;
        height: 33px;
        background: #76B6C4;
        border-radius: 15px;
        background-image: url('/public/img/bandol2.jpg');
        background-position: center;
        display: inline-block;
    }
    
    .i2{
        content: "";
        position: relative;
        width: 33px;
        height: 33px;
        background: #76B6C4;
        border-radius: 15px;
        display: inline-block;
        margin: auto 1rem;
    }
    
    .i3{
        content: "";
        position: relative;
        width: 78px;
        height: 33px;
        background: linear-gradient(180deg, rgba(118,182,196,0) 32%, rgba(118,182,196,1) 100%);
        border-radius: 15px;
        display: inline-block;
        margin: auto 1rem;
    }
    
    .i3::after{
        content: "";
        z-index: 1;
        position: absolute;
        width: 78px;
        height: 33px;
        left: 0;
        bottom: 0;
        border-radius: 15px;
        background-image: url('/public/img/bandol.png');
        display: inline-block;
        background-size: cover;
    }

    /** GRID **/ 

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(9, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
    
    .cards div{
        border-radius: 15px;
        background-size: cover;
    }

    .cards p{
        font-size: 1.167rem;
    }
    
    .cards__el1 { 
        grid-area: 1 / 1 / 5 / 2;; 
        background-image: url('/public/img/IMG_7841.JPG');
    }
    .cards__el2 { 
        grid-area: 1 / 2 / 5 / 3; 
        background-image: url('/public/img/IMG_7109.jpg');
        width: 100%;
        margin: 0 auto;
    
    }
    .cards__el3 { 
        grid-area: 5 / 1 / 7 / 3;
        background-image: url('/public/img/IMG_7106.jpg');
        background-position: center;
        height: 100%;
    }
    .cards__el4 { 
        grid-area: 7 / 1 / 10 / 3; 
        font-family: Poppins;
        color: #7E7E7E;
        font-size: 1rem;
        line-height: 1.4;
        font-weight: 400;
    }

    /* pano */

    .parent{
        max-height: 60vh;
    }

    /* slick */ 

    .container {
        padding: 1.818rem;
        text-align: center;
        width: 80%;
        margin: 2.727rem auto;
    }

    .container h3{
        font-size: 1.167rem;
    }

    /* services */ 

    .services--list{
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        padding: 0;
    }

    .icone{
        width: 40%;
        margin-bottom: 2rem;
    }
    .icone i, .icone img{
        max-width: 45px;
        max-height: 45px;
    }
    
    .icone h3{
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 400;
        padding: 0 1rem;
    }
    .photos div{
        max-height: 80vh;
        width: 100%;
        border-radius: 15px;
    }
    .photos div::after{
        content: "";
        position: absolute;
        top: 1rem; left: 1rem;
        height: 40px; width: 40px;
        z-index: 1;
        background-image: url('/public/icons/swipe.png');
        background-size: cover;
        opacity: .6;
    }

    .photos img{
        max-height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0;
    }

    /** footer **/ 
    .footer{
        display: block;
    }
    .map{
        max-width: 100%;
    }
    .menu{
        display: block;
        z-index: 1111111111111;
    }

    .ulmenu{
        margin-left: 0;
    }

    nav{
        height: 65px;
    }
    .logo{
        height: 60px;
    }
    .menu{
        top: 10px;
    }
    .menuactive{
        background: #646B4B;
        display: block;
        position: fixed;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        z-index: 10000;
        margin-top: 0;
        padding: 7rem 0 0 0;
    }
    .menuactive a, .menuactive a:visited{
        color: white;
        font-size: 4.364rem;
        text-align: center;
        font-family: a;
    }
    .menu__svg{
        height: 40px;
        width: 40px;
        background-image: url('/public/nav/menu.svg');
    }
    .menu__svg.open{
        background-image: url('/public/nav/menu_cross.svg');
    }

}